import {useTheme} from "@material-ui/styles";
import React from 'react'
import styled from 'styled-components'
import JobCreator from '../../components/jobCreator/jobCreator'
import CanvasHouseViewer from '../../components/canvasHouseViewer/canvasHouseViewer'
import { StyledDrawer } from '../../components/sharedStyles'
import {
	GET_SELECTED_PROPERTY_ID,
	GET_USER_PROPERTIES
} from '../../apollo/queries'
import { UPDATE_SELECTED_PROPERTY_ID } from '../../apollo/mutations'
import { useQuery, useMutation } from '@apollo/react-hooks'
import ClaimsSidebar from '../../components/claimsSidebar'
import Non3dOption from '../../components/non3dOptions'
import HouseDenied from '../../components/activeJobs/HouseDenied'
import HouseNotReady from '../../components/activeJobs/HouseNotReady'
import Loading from '../../components/ui/Loading'
import PropertyPrompt from '../../components/ui/PropertyPrompt'
import DisastrousErrorHandler from '../../components/ui/DisastrousErrorHandler'

export const NewJobContainer = styled.div`
	display: flex;
	align-items: stretch;
	justify-content: space-between;
	flex-direction: row;
	overflow: hidden;
	height: 100%;

	@media (max-width: 1024px) {
		flex-direction: column;
		> * {
			flex: 1;
			display: flex;
			align-items: stretch;
			height: 50%;
		}
	}
	@media (max-width: 850px) {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		padding-top: 56px;
	}
`

export const NewOptionsDrawer = styled(StyledDrawer)`
	& > div {
		position: relative;
		padding: 20px 0;
		z-index: ${props =>
			props.theme && props.theme.zIndex && props.theme.zIndex.appBar
				? props.theme.zIndex.appBar - 101
				: 0};
		${props => props.theme.optionDrawerWidth};
		background-image: linear-gradient(135deg, #2c3e50 0%, #2c3e50 100%);
		box-shadow: inset 2px 0 4px rgba(0, 0, 0, 0.3);

		width: 320px;
		@media (max-width: 1025px) {
			width: 100%;
		}
	}
`
const ClaimNewJob: React.FC = () => {
	const {
		data: { selectedPropertyId }
	} = useQuery(GET_SELECTED_PROPERTY_ID)
	const { data, loading } = useQuery(GET_USER_PROPERTIES)
	const [updateSelectedId] = useMutation(UPDATE_SELECTED_PROPERTY_ID)
	const theme = useTheme();

	if (loading) return <Loading />

	const {
		homeowner: { properties }
	} = data
	const setSelectedPropertyId = id => updateSelectedId({ variables: { id } })

	if (properties.length === 0) {
		//TODO: open modal from here
		return <div>You must first create a property</div>
	}

	let currentProperty = properties.find(({ id }) => id === selectedPropertyId)
	if (properties.length === 1) {
		currentProperty = properties[0]
	}
	if (currentProperty && currentProperty.id !== selectedPropertyId) {
		setSelectedPropertyId(currentProperty.id)
	}

	if (!currentProperty) {
		return (
			<ClaimsSidebar>
				<PropertyPrompt properties={properties} />
			</ClaimsSidebar>
		)
	} else if (currentProperty.ready === 'DENIED') {
		return (
			<ClaimsSidebar>
				<HouseDenied />
			</ClaimsSidebar>
		)
	} else if (currentProperty.ready === 'PAYMENT_FAILED') {
		return (
			<ClaimsSidebar>
				<HouseDenied />
			</ClaimsSidebar>
		)
	} else if (currentProperty.ready !== 'READY') {
		return (
			<ClaimsSidebar>
				<HouseNotReady />
			</ClaimsSidebar>
		)
	}

	return (
		<ClaimsSidebar>
			<NewJobContainer>
				<CanvasHouseViewer
					renoworksId={currentProperty.renoworksId}
					legacyHomeSpecId={currentProperty.legacyHomeSpecId}
					propertySpec={currentProperty.propertySpec}
					newViewer={currentProperty.newViewer}
				>
					<Non3dOption />
				</CanvasHouseViewer>
				<NewOptionsDrawer anchor="right" variant="permanent" open theme={theme}>
					<JobCreator insurerDemoMode />
				</NewOptionsDrawer>
			</NewJobContainer>
		</ClaimsSidebar>
	)
}

export default () => (
	<DisastrousErrorHandler homeLink="/jobs">
		<ClaimNewJob />
	</DisastrousErrorHandler>
);
