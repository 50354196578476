import React from 'react'
import { Typography } from '@material-ui/core'
import styled from 'styled-components'
import IconCard from '../ui/IconCard'
import Lottie from 'react-lottie'

const animationData = require('../../images/lottie-home.json')

const helperText = 'Some properties cannot be processed for the following ' +
  'reasons: excessive tree coverage, the property is too newly built, ' +
  'or the location of the property is within a no-fly zone. Your card on file will ' +
  'not be charged if we are unable to process your request.'

const HouseNotReady = () => (
  <Outer>
    <IconCard icon={<Lottie options={{ animationData, autoplay: true, loop: true }} height={100} width={100} />}>
      <div>
        <Typography variant="h6">Under Construction</Typography>
        <Typography variant="subtitle1" style={{ maxWidth: 524 }}>We are processing your property. We will notify you via email<wbr /> when it has been successfully returned and is ready to use</Typography>
      </div>
    </IconCard>
    <Disclaimer>{helperText}</Disclaimer>
  </Outer>
)

export default HouseNotReady


const Outer = styled.div`
	display: flex;
	flex: 1;
	align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
`

const Disclaimer = styled(Typography)`
  font-size: 12px;
  opacity: .75;
  position: absolute;
  bottom: 16px;
  text-align: center;
  color: white;
  max-width: 780px;
  font-style: italic;
`
