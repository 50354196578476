import React from 'react'
import { Avatar } from '@material-ui/core'
import {
	StarHalfOutlined as StarHalf,
	StarOutlined as Star,
	StarBorderOutlined as StarBorder
} from '@material-ui/icons'
import styled from 'styled-components'

import { nameAbbr } from '../../utils/helpers'

interface Props {
	src?: string
	name?: string
	size?: number
	rating?: number
}

const FallbackAvatar = ({ src, name, size, rating }: Props) => (
	<Outer>
		<StyledAvatar alt={name || 'Avatar'} src={src} size={size}>
			{nameAbbr(name || '')}
		</StyledAvatar>
		<Stars>
			{[1, 2, 3, 4, 5].map(r => {
				if (r - 0.5 === rating) {
					return (
						<StarHalf style={{ fontSize: Math.min((size || 82) / 4, 32) }} />
					)
				} else if (rating >= r) {
					return <Star style={{ fontSize: Math.min((size || 82) / 4, 32) }} />
				} else {
					return (
						<StarBorder style={{ fontSize: Math.min((size || 82) / 4, 32) }} />
					)
				}
			})}
		</Stars>
	</Outer>
)

export default React.memo(FallbackAvatar)

const Stars = styled.div`
	&& {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		bottom: 0px;
		left: 0;
		right: 0;
		> * {
			flex: 1;
			margin-left: -1px;
			margin-right: -1px;
		}
		* {
			color: #ffc107 !important;
			border-color: #ffc107 !important;
		}
	}
`

const StyledAvatar = styled(Avatar)<{ size?: number }>`
	background-color: #3f51b5;
	width: ${({ size }) => size || 82}px;
	height: ${({ size }) => size || 82}px;
	font-size: ${({ size }) => (size || 82) * 0.42}px;
	border: 1px solid rgb(255, 255, 255, 0.3);
	* {
		margin-bottom: 0;
	}
`

const Outer = styled.div`
	position: relative;
`
